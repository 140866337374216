.react-viewer-toolbar{
  display: none;
}
.react-viewer-img-details{
  display: none;
}

p.react-viewer-attribute {
  display: block;
  background: black;
  padding: 0.6rem;
  color: white;
  white-space: break-spaces;
  white-space-collapse: preserve;
  margin: 0;
}

/* p.react-viewer-attribute::after{
  content: " ";
} */

.login .Polaris-TextField{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.2rem;
  background-color: #e8f0fe;  
}

.login .Polaris-TextField__Input{
  text-indent: 1.2rem;
  font-weight: 400;
  font-size: 2rem;
  background-color: #e8f0fe;
}

.login .Polaris-TextField__Prefix{
  background-color: #e8f0fe;
  text-indent: 1.2rem;
  width: 100%;
  margin: unset;
}

.login .Polaris-Card{max-width:500px;}

/* .Polaris-TopBar__Contents{background: #e8eef3;} */
/* .Polaris-TopBar{background: #dde7ed;} */


.layout-home{
  position: absolute;
  /* margin-top: 20vh; */
  width: 96vw;
}

.layout-home .layout-login{
  width: 50%;
  position: absolute;
  bottom: 1rem;
  z-index: 1;
}

.layout-home .home-bg{
  /* background-image: url(https://cdn.shopifycdn.net/s/files/1/0507/2345/5144/files/express-bg2.jpg); */
  background-position: center;
  background-size: left;
  border-radius: 1rem;
  background-size: cover;
  width: unset;
  height: 96vh;
  margin-left: 10%;  
}

.login .Polaris-Thumbnail{
  border: unset;
}

.custom .Polaris-Banner--withinContentContainer{
  padding: 0.6rem 0.6rem 0.4rem;
  box-shadow: unset;
}

.resourceItem.flex{
  display: flex;
  align-items: center;
  justify-content: space-around;  
}
.resourceItem.flex div:nth-child(n) {
  flex: 1;
}
.custom-header-loading{
  background: white;
  position: absolute;
  width: 100%;
  left: 0;
  top: 36px;
  background: white;
  z-index: 99;
}
.resourceItem.flex.header{
  position: absolute;
  left: 0;
  top: 36px;
  z-index: 99;
  width: 100%;
  background: wheat;
}
.custom-header-loading .Polaris-Banner--withinContentContainer {
  padding: 1rem;
}

.image-inventorynumber {
  position: relative;
  background: var(--p-text-on-dark);
  border-radius: var(--p-border-radius-large);
}

.image-inventorynumber .Polaris-Spinner {
  position: absolute;
  top: 50%;
  margin-top: -25%;
  left: 50%;
  margin-left: -25%;
}

.image-inventorynumber img {
  /* position: absolute; */
}

.pl-image-rnum-temp {
  position: absolute;
  top: 0;
}

.pl-image-alt {
  text-align: center;
  position: absolute;
  top: 100%;
  width: 100%;
}

.img-grid{
  grid-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(4,1fr);
}

.img-grid-item .add-img{
  position: absolute;
  font-size: 3rem;
  font-weight: 100;
  top: 50%;
  margin-top: -1rem;
  left: 50%;
  margin-left: -1rem;
}

.img-grid-item.ctl{
  background: unset;
  border-style: dashed;
  border-width: 0.2rem;
}

.img-grid-item {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  cursor: pointer;
  background: var(--p-backdrop);
  border: 1px solid #00000030;
  border-radius: 0.7rem;
  overflow: hidden;
}

.img-grid-item:hover .img-mask{
  display: block;
}

.img-grid-item .img-mask{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000068;
  display: none;
}

.img-mask .Polaris-Icon {
  margin-top: calc(50% - 10px);
}

.img-grid-item img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.img-grid>:first-child {
  grid-column: 1/span 2;
  grid-row: 1/span 2;
}

.img-grid-item:after {
  content: " ";
  display: block;
  padding-bottom: 100%;
  width: 100%;
}

.Polaris-IndexTable__TableCell .Polaris-Stack__Item .Polaris-Icon.Polaris-Icon--colorSubdued {
  width: 1.2rem;
  height: 1.2rem;
}

#html5qr-code-full-region__scan_region{
  display: none;
}

.Polaris-DatePicker__DayCell .Polaris-DatePicker__Day--today {
  color: var(--p-action-primary);
}
.Polaris-DatePicker__DayCell .Polaris-DatePicker__Day--selected{
  color: var(--p-text-on-interactive);
}

.Polaris-DatePicker__DayCell .Polaris-DatePicker__Day--today.Polaris-DatePicker__Day--disabled {
  color: var(--p-text-disabled);
}

#setimages .Polaris-Button.Polaris-Button--pressed {
  background-color: var(--p-interactive-on-dark);
}

@media only screen and (max-width: 480px){
  .layout-home{position: relative;width: 100%;margin: unset;}
  .layout-home .home-bg{margin: unset;border-radius: unset;}
  .layout-home .layout-login{width: 100%; bottom: unset;margin-top: 20vh;}
}

@media only screen and (max-width: 768px) and (min-width: 481px){
  .layout-home .layout-login{width: 100%; bottom: 0;}
  .layout-home .home-bg{margin-left:unset;}
}

@media only screen and (min-width: 769px){

}

@media ((min-width: 110em)){
  .inventory-img-layout .Polaris-Stack {
    flex-direction: unset;
  }
}

@media ((max-width: 100em)){
  .inventory-img-layout .Polaris-Stack {
    flex-direction: unset;
  }
  .baseinfo .Polaris-Stack {
    flex-wrap: wrap;
  }
}